.u-section-2 {
    min-height: 500px;
    background-image: url("//images01.nicepage.com/c461c07a441a5d220e8feb1a/926c025df7935b36bca6de01/cf53118a-260c-4c88-1a3e-07bdd3ca1ac8.jpg");
    background-position: 50% 50%;
  }
  .u-section-2 .u-layout-wrap-1 {
    width: calc(((100% - 1140px) / 2) + 1140px);
    margin: 0 0 0 auto;
  }
  .u-section-2 .u-layout-cell-1 {
    min-height: 691px;
  }
  .u-section-2 .u-container-layout-1 {
    padding: 50px 30px 50px 40px;
  }
  .u-section-2 .u-text-1 {
    font-size: 1.25rem;
    line-height: 1.2;
    background-image: none;
    letter-spacing: 6px;
    font-family: "PT Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
  }
  .u-section-2 .u-text-2 {
    font-size: 3.75rem;
    letter-spacing: normal;
    font-family: Lato, sans-serif;
    font-weight: 700;
    text-transform: none;
    margin: 9px 0 0;
  }
  .u-section-2 .u-text-3 {
    margin: 18px 0 0;
  }
  .u-section-2 .u-btn-1 {
    border-style: none none solid;
    padding: 0;
  }
  .u-section-2 .u-btn-2 {
    background-image: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 1.5rem;
    border-style: none;
    margin: 45px auto 0 0;
    padding: 10px 70px;
  }
  .u-section-2 .u-image-1 {
    min-height: 691px;
    background-image: url("//images01.nicepage.com/c461c07a441a5d220e8feb1a/4661f68e8bf75214a5306400/-min.jpg");
    background-position: 50% 50%;
  }
  .u-section-2 .u-container-layout-2 {
    padding: 30px;
  }
  @media (max-width: 1199px) {
    .u-section-2 {
      min-height: 291px;
    }
    .u-section-2 .u-layout-wrap-1 {
      width: calc(((100% - 940px) / 2) + 940px);
    }
    .u-section-2 .u-layout-cell-1 {
      min-height: 529px;
    }
    .u-section-2 .u-text-2 {
      font-size: 3.125rem;
    }
    .u-section-2 .u-image-1 {
      min-height: 605px;
    }
  }
  @media (max-width: 991px) {
    .u-section-2 {
      min-height: 467px;
    }
    .u-section-2 .u-layout-wrap-1 {
      width: calc(((100% - 720px) / 2) + 720px);
    }
    .u-section-2 .u-layout-cell-1 {
      min-height: 100px;
    }
    .u-section-2 .u-container-layout-1 {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
    }
    .u-section-2 .u-text-1 {
      margin-top: 15px;
    }
    .u-section-2 .u-text-2 {
      font-size: 2.5rem;
    }
    .u-section-2 .u-image-1 {
      min-height: 423px;
      background-position: 33.42% 50%;
    }
  }
  @media (max-width: 767px) {
    .u-section-2 {
      min-height: 577px;
    }
    .u-section-2 .u-layout-wrap-1 {
      width: calc(((100% - 540px) / 2) + 540px);
    }
    .u-section-2 .u-container-layout-1 {
      padding-top: 50px;
      padding-right: 108px;
      padding-left: 10px;
    }
    .u-section-2 .u-text-2 {
      font-size: 3.75rem;
    }
    .u-section-2 .u-image-1 {
      min-height: 614px;
    }
    .u-section-2 .u-container-layout-2 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 575px) {
    .u-section-2 {
      min-height: 738px;
    }
    .u-section-2 .u-layout-wrap-1 {
      width: calc(((100% - 340px) / 2) + 340px);
    }
    .u-section-2 .u-layout-cell-1 {
      min-height: 372px;
    }
    .u-section-2 .u-container-layout-1 {
      padding-top: 30px;
      padding-right: 55px;
    }
    .u-section-2 .u-text-2 {
      font-size: 2.25rem;
    }
    .u-section-2 .u-image-1 {
      min-height: 421px;
    }
  }
  .u-section-3 {
    background-image: none;
  }
  .u-section-3 .u-sheet-1 {
    min-height: 575px;
  }
  .u-section-3 .u-list-1 {
    width: 1140px;
    margin-top: 50px;
    margin-bottom: 0;
    min-height: 375px;
    grid-template-rows: repeat(1, auto);
  }
  .u-section-3 .u-repeater-1 {
    grid-auto-columns: calc(33.3333% - 16.6667px);
    grid-template-columns: repeat(3, calc(33.3333% - 16.6667px));
    grid-auto-rows: 100%;
    grid-gap: 25px;
  }
  .u-section-3 .u-list-item-1 {
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  }
  .u-section-3 .u-container-layout-1 {
    padding: 30px;
  }
  .u-section-3 .u-image-1 {
    height: 333px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  .u-section-3 .u-text-1 {
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0 0;
  }
  .u-section-3 .u-text-2 {
    margin: 30px 0 0;
  }
  .u-section-3 .u-btn-1 {
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-image: none;
    border-style: none none solid;
    text-transform: uppercase;
    font-weight: 600;
    height: 38px;
    margin: 20px auto 0;
    padding: 5px 0;
  }
  .u-section-3 .u-list-item-2 {
    background-image: none;
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  }
  .u-section-3 .u-container-layout-2 {
    padding: 30px;
  }
  .u-section-3 .u-image-2 {
    height: 333px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  .u-section-3 .u-text-3 {
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0 0;
  }
  .u-section-3 .u-text-4 {
    margin: 30px 0 0;
  }
  .u-section-3 .u-btn-2 {
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-image: none;
    border-style: none none solid;
    text-transform: uppercase;
    font-weight: 600;
    height: 38px;
    margin: 20px auto 0;
    padding: 5px 0;
  }
  .u-section-3 .u-list-item-3 {
    background-image: none;
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.4);
  }
  .u-section-3 .u-container-layout-3 {
    padding: 30px;
  }
  .u-section-3 .u-image-3 {
    height: 333px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  .u-section-3 .u-text-5 {
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0 0;
  }
  .u-section-3 .u-text-6 {
    margin: 30px 0 0;
  }
  .u-section-3 .u-btn-3 {
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-image: none;
    border-style: none none solid;
    text-transform: uppercase;
    font-weight: 600;
    height: 38px;
    margin: 20px auto 0;
    padding: 5px 0;
  }
  .u-section-3 .u-text-7 {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.8;
    width: 556px;
    margin: 30px 292px 60px;
  }
  .u-section-3 .u-btn-4 {
    border-style: none none solid;
    padding: 0;
  }
  @media (max-width: 1199px) {
    .u-section-3 .u-sheet-1 {
      min-height: 509px;
    }
    .u-section-3 .u-list-1 {
      width: 940px;
      min-height: 309px;
    }
    .u-section-3 .u-repeater-1 {
      grid-auto-columns: calc(33.333333333333336% - 16.6667px);
      grid-template-columns: repeat(3, calc(33.333333333333336% - 16.6667px));
    }
    .u-section-3 .u-image-1 {
      height: 213px;
      margin-left: initial;
    }
    .u-section-3 .u-btn-1 {
      width: 99px;
    }
    .u-section-3 .u-image-2 {
      height: 213px;
      margin-left: initial;
    }
    .u-section-3 .u-btn-2 {
      width: 99px;
    }
    .u-section-3 .u-image-3 {
      height: 213px;
      margin-left: initial;
    }
    .u-section-3 .u-btn-3 {
      width: 99px;
    }
    .u-section-3 .u-text-7 {
      margin-left: 192px;
      margin-right: 192px;
    }
  }
  @media (max-width: 991px) {
    .u-section-3 .u-sheet-1 {
      min-height: 910px;
    }
    .u-section-3 .u-list-1 {
      width: 720px;
      min-height: 711px;
    }
    .u-section-3 .u-repeater-1 {
      grid-auto-columns: calc(50% - 12.5px);
      grid-template-columns: repeat(2, calc(50% - 12.5px));
      grid-auto-rows: calc(50% - 12.5px);
    }
    .u-section-3 .u-text-7 {
      margin-left: 82px;
      margin-right: 82px;
    }
  }
  @media (max-width: 767px) {
    .u-section-3 .u-sheet-1 {
      min-height: 1798px;
    }
    .u-section-3 .u-list-1 {
      min-height: 2168px;
      height: auto;
      width: auto;
    }
    .u-section-3 .u-repeater-1 {
      grid-auto-columns: calc(100% - 0px);
      grid-template-columns: repeat(1, 100%);
      grid-auto-rows: calc(33.33% - 16.6667px);
    }
    .u-section-3 .u-image-1 {
      height: 441px;
      margin-left: initial;
    }
    .u-section-3 .u-text-1 {
      width: 306px;
      margin-left: auto;
      margin-right: auto;
    }
    .u-section-3 .u-text-2 {
      width: auto;
    }
    .u-section-3 .u-image-2 {
      height: 441px;
      margin-left: initial;
    }
    .u-section-3 .u-text-3 {
      width: 306px;
      margin-left: auto;
      margin-right: auto;
    }
    .u-section-3 .u-text-4 {
      width: auto;
    }
    .u-section-3 .u-image-3 {
      height: 441px;
      margin-left: initial;
    }
    .u-section-3 .u-text-5 {
      width: 306px;
      margin-left: auto;
      margin-right: auto;
    }
    .u-section-3 .u-text-6 {
      width: auto;
    }
    .u-section-3 .u-text-7 {
      width: 540px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  @media (max-width: 575px) {
    .u-section-3 .u-sheet-1 {
      min-height: 1206px;
    }
    .u-section-3 .u-list-1 {
      min-height: 1365px;
      width: auto;
    }
    .u-section-3 .u-repeater-1 {
      grid-auto-columns: 100%;
      grid-auto-rows: calc(33.33% - 16.666666666666668px);
    }
    .u-section-3 .u-container-layout-1 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-3 .u-image-1 {
      height: 292px;
      margin-left: initial;
    }
    .u-section-3 .u-container-layout-2 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-3 .u-image-2 {
      height: 292px;
      margin-left: initial;
    }
    .u-section-3 .u-container-layout-3 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-3 .u-image-3 {
      height: 292px;
      margin-left: initial;
    }
    .u-section-3 .u-text-7 {
      width: 340px;
    }
  }
  .u-section-4 .u-sheet-1 {
    min-height: 1091px;
  }
  .u-section-4 .u-layout-wrap-1 {
    width: 869px;
    margin: 40px auto;
  }
  .u-section-4 .u-layout-cell-1 {
    min-height: 607px;
  }
  .u-section-4 .u-container-layout-1 {
    padding: 30px;
  }
  .u-section-4 .u-image-1 {
    width: 460px;
    height: 285px;
    margin: 0 -106px 0 0;
  }
  .u-section-4 .u-text-1 {
    line-height: 2;
    margin: 20px 0 0;
  }
  .u-section-4 .u-text-2 {
    font-size: 1rem;
    line-height: 1.8;
    font-weight: 400;
    margin: 20px 0 0;
  }
  .u-section-4 .u-btn-1 {
    border-style: none none solid;
    padding: 0;
  }
  .u-section-4 .u-layout-cell-2 {
    min-height: 434px;
  }
  .u-section-4 .u-container-layout-2 {
    padding: 30px 79px 30px 30px;
  }
  .u-section-4 .u-image-2 {
    height: 344px;
    width: 271px;
    margin: 0 auto 0 0;
  }
  .u-section-4 .u-layout-cell-3 {
    min-height: 440px;
  }
  .u-section-4 .u-container-layout-3 {
    padding: 30px 30px 30px 83px;
  }
  .u-section-4 .u-text-3 {
    font-size: 3.125rem;
    margin: 0 1px;
  }
  .u-section-4 .u-shape-1 {
    width: 297px;
    height: 60px;
    margin: 30px auto 0 1px;
  }
  .u-section-4 .u-layout-cell-4 {
    min-height: 553px;
  }
  .u-section-4 .u-container-layout-4 {
    padding: 40px 30px 30px 0;
  }
  .u-section-4 .u-image-3 {
    height: 238px;
    margin: 0 auto 0 0;
  }
  .u-section-4 .u-text-4 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
    line-height: 2;
  }
  @media (max-width: 1199px) {
    .u-section-4 .u-image-3 {
      margin-right: initial;
      margin-left: initial;
    }
  }
  @media (max-width: 991px) {
    .u-section-4 .u-sheet-1 {
      min-height: 1069px;
    }
    .u-section-4 .u-layout-wrap-1 {
      width: 720px;
    }
    .u-section-4 .u-layout-cell-1 {
      min-height: 657px;
    }
    .u-section-4 .u-image-1 {
      width: 605px;
      height: 403px;
      margin-right: auto;
    }
    .u-section-4 .u-layout-cell-2 {
      min-height: 719px;
    }
    .u-section-4 .u-container-layout-2 {
      padding-right: 30px;
    }
    .u-section-4 .u-image-2 {
      height: 629px;
      width: 418px;
    }
    .u-section-4 .u-layout-cell-3 {
      min-height: 100px;
    }
    .u-section-4 .u-container-layout-3 {
      padding-left: 30px;
    }
    .u-section-4 .u-layout-cell-4 {
      min-height: 100px;
    }
    .u-section-4 .u-container-layout-4 {
      padding-top: 30px;
      padding-left: 0;
    }
    .u-section-4 .u-image-3 {
      height: 406px;
      margin-right: initial;
      margin-left: initial;
    }
  }
  @media (max-width: 767px) {
    .u-section-4 .u-sheet-1 {
      min-height: 889px;
    }
    .u-section-4 .u-layout-wrap-1 {
      width: 540px;
    }
    .u-section-4 .u-layout-cell-1 {
      min-height: 100px;
    }
    .u-section-4 .u-container-layout-1 {
      padding-left: 10px;
      padding-right: 10px;
    }
    .u-section-4 .u-image-1 {
      width: 520px;
      height: 346px;
    }
    .u-section-4 .u-layout-cell-2 {
      min-height: 539px;
    }
    .u-section-4 .u-container-layout-2 {
      padding-right: 10px;
      padding-left: 10px;
    }
    .u-section-4 .u-container-layout-3 {
      padding-right: 10px;
      padding-left: 10px;
    }
    .u-section-4 .u-text-3 {
      margin-left: 0;
      margin-right: 0;
    }
    .u-section-4 .u-shape-1 {
      margin-left: 0;
    }
    .u-section-4 .u-container-layout-4 {
      padding-right: 10px;
    }
    .u-section-4 .u-image-3 {
      height: 312px;
      margin-right: initial;
      margin-left: initial;
    }
  }
  @media (max-width: 575px) {
    .u-section-4 .u-sheet-1 {
      min-height: 689px;
    }
    .u-section-4 .u-layout-wrap-1 {
      width: 340px;
    }
    .u-section-4 .u-image-1 {
      width: 320px;
      height: 213px;
    }
    .u-section-4 .u-layout-cell-2 {
      min-height: 339px;
    }
    .u-section-4 .u-image-2 {
      height: 482px;
      width: 320px;
    }
    .u-section-4 .u-text-3 {
      font-size: 3rem;
    }
    .u-section-4 .u-image-3 {
      height: 194px;
      margin-right: initial;
      margin-left: initial;
    }
  }
  .u-section-5 {
    background-image: none;
  }
  .u-section-5 .u-sheet-1 {
    min-height: 894px;
  }
  .u-section-5 .u-layout-wrap-1 {
    margin: 60px 0;
  }
  .u-section-5 .u-image-1 {
    min-height: 560px;
    background-image: url("//images01.nicepage.com/c461c07a441a5d220e8feb1a/041d7210b0d35edd8114845d/43c72830-c184-6d69-dc2c-9110d9660ad6.jpg");
    background-position: 50% 50%;
  }
  .u-section-5 .u-container-layout-1 {
    padding: 20px;
  }
  .u-section-5 .u-layout-cell-2 {
    min-height: 187px;
  }
  .u-section-5 .u-container-layout-2 {
    padding: 30px 30px 30px 0;
  }
  .u-section-5 .u-text-1 {
    font-size: 2.25rem;
    margin: 0;
  }
  .u-section-5 .u-text-2 {
    margin: 20px 0 0;
  }
  .u-section-5 .u-text-3 {
    margin: 20px 0 0;
  }
  .u-section-5 .u-btn-1 {
    border-style: none none solid;
    padding: 0;
  }
  .u-section-5 .u-layout-cell-3 {
    min-height: 754px;
  }
  .u-section-5 .u-container-layout-3 {
    padding-top: 120px;
  }
  .u-section-5 .u-shape-1 {
    height: 430px;
    width: 433px;
    background-image: none;
    margin: 79px 0 0 auto;
  }
  .u-section-5 .u-image-2 {
    width: 485px;
    height: 444px;
    margin: -509px auto 0 0;
  }
  @media (max-width: 1199px) {
    .u-section-5 .u-sheet-1 {
      min-height: 762px;
    }
    .u-section-5 .u-layout-wrap-1 {
      margin-right: initial;
      margin-left: initial;
    }
    .u-section-5 .u-image-1 {
      min-height: 461px;
    }
    .u-section-5 .u-layout-cell-2 {
      min-height: 154px;
    }
    .u-section-5 .u-layout-cell-3 {
      min-height: 622px;
    }
    .u-section-5 .u-container-layout-3 {
      padding-top: 50px;
    }
    .u-section-5 .u-shape-1 {
      height: 392px;
      width: 395px;
    }
    .u-section-5 .u-image-2 {
      height: 411px;
      width: 395px;
      margin-top: -471px;
    }
  }
  @media (max-width: 991px) {
    .u-section-5 .u-sheet-1 {
      min-height: 1106px;
    }
    .u-section-5 .u-image-1 {
      min-height: 511px;
    }
    .u-section-5 .u-layout-cell-2 {
      min-height: 100px;
    }
    .u-section-5 .u-container-layout-2 {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 0;
    }
    .u-section-5 .u-layout-cell-3 {
      min-height: 430px;
    }
    .u-section-5 .u-container-layout-3 {
      padding-top: 30px;
    }
    .u-section-5 .u-shape-1 {
      height: 365px;
      width: 502px;
      margin-top: 35px;
    }
    .u-section-5 .u-image-2 {
      width: 660px;
      height: 369px;
      margin-top: -430px;
    }
  }
  @media (max-width: 767px) {
    .u-section-5 .u-sheet-1 {
      min-height: 870px;
    }
    .u-section-5 .u-image-1 {
      min-height: 646px;
    }
    .u-section-5 .u-container-layout-1 {
      padding-left: 10px;
      padding-right: 10px;
    }
    .u-section-5 .u-container-layout-2 {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 10px;
    }
    .u-section-5 .u-layout-cell-3 {
      min-height: 419px;
    }
    .u-section-5 .u-container-layout-3 {
      padding-top: 50px;
    }
    .u-section-5 .u-shape-1 {
      height: 333px;
      width: 466px;
    }
    .u-section-5 .u-image-2 {
      width: 480px;
      height: 323px;
      margin-top: -383px;
    }
  }
  @media (max-width: 575px) {
    .u-section-5 .u-sheet-1 {
      min-height: 1029px;
    }
    .u-section-5 .u-layout-wrap-1 {
      margin-top: 54px;
      margin-bottom: 54px;
      margin-right: initial;
      margin-left: initial;
    }
    .u-section-5 .u-image-1 {
      min-height: 375px;
    }
    .u-section-5 .u-layout-cell-2 {
      min-height: 261px;
    }
    .u-section-5 .u-text-1 {
      font-size: 1.875rem;
    }
    .u-section-5 .u-layout-cell-3 {
      min-height: 264px;
    }
    .u-section-5 .u-container-layout-3 {
      padding-top: 30px;
    }
    .u-section-5 .u-shape-1 {
      height: 199px;
      width: 300px;
    }
    .u-section-5 .u-image-2 {
      width: 300px;
      height: 196px;
      margin-top: -229px;
    }
  }
  .u-section-6 .u-sheet-1 {
    min-height: 1076px;
  }
  .u-section-6 .u-shape-1 {
    width: 637px;
    height: 603px;
    margin: 79px auto 0 0;
  }
  .u-section-6 .u-image-1 {
    width: 761px;
    height: 579px;
    margin: -429px 105px 0 auto;
  }
  .u-section-6 .u-group-1 {
    width: 519px;
    min-height: 519px;
    background-image: none;
    margin: -832px auto 0 62px;
  }
  .u-section-6 .u-container-layout-1 {
    padding: 30px;
  }
  .u-section-6 .u-text-1 {
    font-size: 3.75rem;
    font-weight: 700;
    margin: 0;
  }
  .u-section-6 .u-text-2 {
    line-height: 1.8;
    margin: 20px 0 0;
  }
  .u-section-6 .u-btn-1 {
    border-style: none none solid;
    padding: 0;
  }
  .u-section-6 .u-text-3 {
    font-size: 1rem;
    margin: 20px 62px 0 0;
  }
  .u-section-6 .u-btn-2 {
    border-style: none none solid;
    padding: 0;
  }
  .u-section-6 .u-btn-3 {
    background-image: none;
    border-style: none;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 31px auto 0 0;
    padding: 10px 41px;
  }
  .u-section-6 .u-layout-wrap-1 {
    width: 618px;
    margin: 183px 0 60px auto;
  }
  .u-section-6 .u-layout-cell-1 {
    min-height: 314px;
  }
  .u-section-6 .u-container-layout-2 {
    padding: 30px;
  }
  .u-section-6 .u-text-4 {
    font-weight: 700;
    margin: 0;
  }
  .u-section-6 .u-text-5 {
    margin: 20px 0 0;
  }
  .u-section-6 .u-image-2 {
    min-height: 314px;
    background-image: url("//images01.nicepage.com/c461c07a441a5d220e8feb1a/8894714a1bf05344879c20fc/pretty-young-lady-dressed-raincoat-sitting-car_171337-3516.jpg");
    background-position: 50% 50%;
  }
  .u-section-6 .u-container-layout-3 {
    padding: 30px;
  }
  @media (max-width: 1199px) {
    .u-section-6 .u-image-1 {
      width: 743px;
      height: 516px;
      margin-top: -375px;
      margin-right: 79px;
    }
    .u-section-6 .u-group-1 {
      width: 481px;
      min-height: 510px;
      margin-top: -823px;
    }
    .u-section-6 .u-layout-wrap-1 {
      margin-top: 192px;
    }
  }
  @media (max-width: 991px) {
    .u-section-6 .u-sheet-1 {
      min-height: 1077px;
    }
    .u-section-6 .u-shape-1 {
      width: 568px;
      height: 524px;
    }
    .u-section-6 .u-image-1 {
      width: 560px;
      height: 408px;
      margin-top: -214px;
      margin-right: 38px;
    }
    .u-section-6 .u-group-1 {
      width: 444px;
      margin-top: -796px;
    }
    .u-section-6 .u-text-1 {
      font-size: 3.4375rem;
    }
    .u-section-6 .u-layout-wrap-1 {
      width: 506px;
      margin-top: 193px;
    }
  }
  @media (max-width: 767px) {
    .u-section-6 .u-sheet-1 {
      min-height: 1346px;
    }
    .u-section-6 .u-shape-1 {
      width: 506px;
      height: 475px;
    }
    .u-section-6 .u-image-1 {
      height: 327px;
      width: 449px;
      margin-top: -118px;
      margin-right: 0;
    }
    .u-section-6 .u-group-1 {
      width: 416px;
      min-height: 490px;
      margin-top: -763px;
      margin-left: 45px;
    }
    .u-section-6 .u-container-layout-1 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-6 .u-text-1 {
      font-size: 3rem;
    }
    .u-section-6 .u-layout-wrap-1 {
      width: 357px;
      margin-top: 209px;
      margin-right: auto;
      margin-left: 0;
    }
    .u-section-6 .u-layout-cell-1 {
      min-height: 191px;
    }
    .u-section-6 .u-image-2 {
      min-height: 396px;
    }
    .u-section-6 .u-container-layout-3 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 575px) {
    .u-section-6 .u-sheet-1 {
      min-height: 1224px;
    }
    .u-section-6 .u-shape-1 {
      width: 340px;
      margin-right: 0;
      margin-left: auto;
    }
    .u-section-6 .u-image-1 {
      height: 263px;
      width: 320px;
      margin-top: -103px;
    }
    .u-section-6 .u-group-1 {
      width: 303px;
      min-height: 504px;
      margin-top: -714px;
      margin-right: 0;
      margin-left: auto;
    }
    .u-section-6 .u-text-1 {
      font-size: 2.25rem;
    }
    .u-section-6 .u-text-2 {
      width: auto;
    }
    .u-section-6 .u-text-3 {
      margin-right: 6px;
    }
    .u-section-6 .u-layout-wrap-1 {
      width: 303px;
      margin-top: 166px;
      margin-bottom: -35px;
    }
    .u-section-6 .u-layout-cell-1 {
      min-height: 183px;
    }
    .u-section-6 .u-container-layout-2 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-6 .u-text-4 {
      width: auto;
    }
    .u-section-6 .u-image-2 {
      min-height: 286px;
    }
  }
  .u-section-7 {
    background-image: url("//images01.nicepage.com/c461c07a441a5d220e8feb1a/639d93f58f745e2d93fa9efa/-min.jpg");
    background-position: 50% 50%;
  }
  .u-section-7 .u-sheet-1 {
    min-height: 780px;
  }
  .u-section-7 .u-group-1 {
    width: 477px;
    min-height: 574px;
    margin: 0 auto 60px 0;
  }
  .u-section-7 .u-container-layout-1 {
    padding: 30px;
  }
  .u-section-7 .u-image-1 {
    width: 209px;
    height: 134px;
    margin: 0 auto;
  }
  .u-section-7 .u-text-1 {
    font-size: 1.875rem;
    font-weight: 300;
    font-family: Abel;
    margin: 20px 0 0;
  }
  .u-section-7 .u-text-2 {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.8;
    margin: 45px 0 0;
  }
  .u-section-7 .u-btn-1 {
    border-style: none none solid;
    padding: 0;
  }
  @media (max-width: 1199px) {
    .u-section-7 .u-sheet-1 {
      min-height: 643px;
    }
    .u-section-7 .u-group-1 {
      width: 361px;
      min-height: 583px;
    }
  }
  @media (max-width: 991px) {
    .u-section-7 .u-group-1 {
      width: 337px;
      min-height: 548px;
    }
    .u-section-7 .u-text-1 {
      font-size: 1.625rem;
    }
  }
  @media (max-width: 767px) {
    .u-section-7 .u-sheet-1 {
      min-height: 608px;
    }
    .u-section-7 .u-group-1 {
      width: 302px;
      min-height: 547px;
    }
  }
  @media (max-width: 575px) {
    .u-section-7 .u-sheet-1 {
      min-height: 607px;
    }
    .u-section-7 .u-group-1 {
      width: 270px;
      min-height: 550px;
      margin-bottom: 57px;
    }
    .u-section-7 .u-container-layout-1 {
      padding-left: 20px;
      padding-right: 20px;
    }
    .u-section-7 .u-image-1 {
      width: 180px;
      height: 120px;
    }
  }
  .u-section-8 {
    background-image: none;
  }
  .u-section-8 .u-sheet-1 {
    min-height: 500px;
  }
  .u-section-8 .u-layout-wrap-1 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .u-section-8 .u-layout-cell-1 {
    min-height: 400px;
  }
  .u-section-8 .u-container-layout-1 {
    padding: 30px;
  }
  .u-section-8 .u-text-1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.25rem;
    margin: 0 118px 0 0;
  }
  .u-section-8 .u-text-2 {
    font-weight: 700;
    line-height: 1.8;
    font-style: italic;
    font-size: 1.125rem;
    margin: 20px 0 0;
  }
  .u-section-8 .u-text-3 {
    line-height: 2;
    margin: 20px 0 0;
  }
  .u-section-8 .u-social-icons-1 {
    white-space: nowrap;
    height: 32px;
    min-height: 16px;
    width: 200px;
    min-width: 120px;
    margin: 30px auto 0 0;
  }
  .u-section-8 .u-icon-1 {
    height: 100%;
  }
  .u-section-8 .u-icon-2 {
    height: 100%;
  }
  .u-section-8 .u-icon-3 {
    height: 100%;
  }
  .u-section-8 .u-icon-4 {
    height: 100%;
  }
  .u-section-8 .u-icon-5 {
    height: 100%;
  }
  .u-section-8 .u-layout-cell-2 {
    min-height: 400px;
  }
  .u-section-8 .u-container-layout-2 {
    padding: 30px;
  }
  .u-section-8 .u-text-4 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0;
  }
  .u-section-8 .u-text-5 {
    margin: 20px 205px 0 0;
  }
  .u-section-8 .u-text-6 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25rem;
    margin: 20px 0 0;
  }
  .u-section-8 .u-text-7 {
    margin: 18px 119px 0 0;
  }
  .u-section-8 .u-text-8 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25rem;
    margin: 20px 0 0;
  }
  .u-section-8 .u-text-9 {
    margin: 20px 116px 0 0;
  }
  .u-section-8 .u-layout-cell-3 {
    min-height: 400px;
  }
  .u-section-8 .u-container-layout-3 {
    padding: 30px;
  }
  .u-section-8 .u-form-1 {
    height: 317px;
    margin: 0 -60px 0 0;
  }
  .u-section-8 .u-btn-1 {
    border-style: none;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-image: none;
  }
  @media (max-width: 1199px) {
    .u-section-8 .u-sheet-1 {
      min-height: 430px;
    }
    .u-section-8 .u-layout-wrap-1 {
      position: relative;
    }
    .u-section-8 .u-layout-cell-1 {
      min-height: 330px;
    }
    .u-section-8 .u-text-1 {
      margin-right: 51px;
    }
    .u-section-8 .u-layout-cell-2 {
      min-height: 330px;
    }
    .u-section-8 .u-text-5 {
      margin-right: 138px;
    }
    .u-section-8 .u-text-7 {
      margin-right: 52px;
    }
    .u-section-8 .u-text-9 {
      margin-right: 49px;
    }
    .u-section-8 .u-layout-cell-3 {
      min-height: 330px;
    }
    .u-section-8 .u-form-1 {
      margin-right: initial;
      margin-left: initial;
    }
  }
  @media (max-width: 991px) {
    .u-section-8 .u-sheet-1 {
      min-height: 353px;
    }
    .u-section-8 .u-layout-cell-1 {
      min-height: 465px;
    }
    .u-section-8 .u-container-layout-1 {
      padding-left: 10px;
      padding-right: 10px;
    }
    .u-section-8 .u-text-1 {
      margin-right: 0;
    }
    .u-section-8 .u-social-icons-1 {
      height: 27px;
      width: 176px;
      margin-right: 17px;
    }
    .u-section-8 .u-layout-cell-2 {
      min-height: 100px;
    }
    .u-section-8 .u-container-layout-2 {
      padding-left: 10px;
      padding-right: 10px;
    }
    .u-section-8 .u-text-5 {
      margin-right: 65px;
    }
    .u-section-8 .u-text-7 {
      margin-right: 0;
    }
    .u-section-8 .u-text-9 {
      margin-right: 0;
    }
    .u-section-8 .u-layout-cell-3 {
      min-height: 100px;
    }
    .u-section-8 .u-container-layout-3 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: 767px) {
    .u-section-8 .u-sheet-1 {
      min-height: 1146px;
    }
    .u-section-8 .u-layout-cell-1 {
      min-height: 100px;
    }
  }
  @media (max-width: 575px) {
    .u-section-8 .u-sheet-1 {
      min-height: 816px;
    }
    .u-section-8 .u-text-5 {
      margin-right: 0;
    }
  }