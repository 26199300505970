$taxiYellow: #fdb813;
$headerGray: #5e9693;


/** Navbar */

/* Color of the links BEFORE scroll */
.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler-icon,
.navbar-scroll .navbar-brand {
  color: #fff;
}

/* Color of the links AFTER scroll */
.navbar-scrolled .nav-link,
.navbar-scrolled .navbar-toggler-icon,
.navbar-scrolled .navbar-brand {
  color: #fff;
}

/* Color of the navbar AFTER scroll */
.navbar-scroll,
.navbar-scrolled {
  background-color: #cbbcb1;
}

.mask-custom {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, .15);
}

.navbar-brand {
  font-size: 1.75rem;
  letter-spacing: 3px;
}

.bgimg{
  background-blend-mode: darken;
  // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../image/street-night-with-traffic.jpg');
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../image/bgImg.jpg');
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.bgimg2{
  background-blend-mode: darken;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../image/IMG_2140-1.png');
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.text-box-with-shadow{
  background-color: #444;
  box-shadow: 12px 8px 4px 2px #fdb813;
  border-radius: 25px 0px;
  padding: 24px 0px 24px 48px;
  margin-bottom: 25px;
}

.sign {
  min-height: 100%;
  display: flex;

  span {
    text-align: center;
    line-height: 1;
    color: #c6e2ff;
    animation: neon .8s ease-in-out infinite alternate;
  }
}

@keyframes neon {
  from {
    text-shadow:
    0 0 6px rgba(202,228,225,0.92),
    0 0 30px rgba(202,228,225,0.34),
    0 0 12px #fdb813,
    0 0 21px #fdb813,
    0 0 34px #fdb813,
    0 0 54px #fdb813;
  }
  to {
    text-shadow:
    0 0 6px rgba(202,228,225,0.98),
    0 0 30px rgba(202,228,225,0.42),
    0 0 12px #fff,
    0 0 22px #fff,
    0 0 38px #fff,
    0 0 60px #fff;
  }
}

// .outglow{
//   // -webkit-text-fill-color: transparent;
//   -webkit-text-stroke: 0.25px #fff;
// }

.outglow-x{
  -webkit-text-stroke: 0.75px #fff;
}

.outglow-y{
  -webkit-text-stroke: 0.2px #fdb813;
}

.display-x{
  font-size: 120px;
}

.headerBoxLeft{
  width: 100%;
  padding: 20vh 5% 0% 60%;
}

.headerBoxCenter{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20vh 5% 0% 5%;
}

.p0{
  letter-spacing: 5px;
  font-size: 28px;
  font-weight: 400;
  color: #888;
}

.p1{
  font-size: 48px;
  font-weight: 900;
  color: #888;
}

.p2{
  font-size: 32px;
  font-weight: 900;
  color: #888;
}

.p3{
  font-family: 'Times New Roman', Times, serif;
  font-size: 72px;
  font-weight: 900;
  line-height: 70px;
  color: #000;
  padding-top: 25px;
}

.p4{
  font-size: 18px;

}

.s3image{
  padding: 20px 25px 50px 25px; 
  min-width: 300px; 
  width: 90%;
}

.s3imageX{
  padding: 20px 25px 50px 25px; 
  width: 90%;
  min-width: 300px; 
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.s4image1{
  padding-top: 20px;
  padding-bottom: 50px;
  width: 90%;
  min-width: 300px;
  height: auto;
}

.s4image2{
  margin-bottom: 50px;
  width: 70%;
  min-width: 250px;
  height: auto;
  box-shadow: 50px 50px #fff;
}

.yellowLine1{
  height: 45px;
  min-width: 300px;
  width: 100%;
  background-color: #fdb813;
  margin-top: 25px;
}

.yellowLine2{
  height: 15px;
  min-width: 300px;
  width: 100%;
  background-color: #fdb813;
  margin-top: 25px;
}

// Section 2

.iconSet {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  font-size: 150px;
}

.txtHighWeigthSet{
  font-size: 25px;
  font-weight: 800;
}

.txtLowWeigthSet{
  font-size: 25px;
  font-weight: 200;
}

// Section 3

.lineSegment2x {
  overflow: hidden;
  width: 66%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 24px;
  font-weight: 500;
}

.lineSegment2 {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
}

// Section 4

.yellowBG{
  background-color: $taxiYellow;
  padding: 50px;
}

.yellowBGX{
  background-color: $taxiYellow;
}

// Section 5

.aboutbox{
  width: 100%;
  border: 15px solid black;
  padding: 50px;
  margin-left: auto;
  margin-right: auto;
  background: rgb(255,255,255);
}

.aboutboxX{
  width: 100%;
  border: 5px solid black;
  padding: 20px;
  background: rgb(255,255,255);
}

.abouttext{
  width: 100%;
  border: 0px solid black;
  padding: 0px 20px 0px 20px;
  margin-left: auto;
  margin-right: auto;
}

.abouttextX{
  width: 100%;
  border: 0px solid black;
  padding: 40px 10px 20px 10px;
}

// Section 6 

.imgbr{
  border-radius: 5px;
}

.bggray{
  background-color: #888;
}

.bggrayX{
  background-color: #555;
}


// Modal

.brx{
  width:18rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modelImageWeb{
  height: 200px;
  width:auto;
}

.modelImageMobile{
  height: auto;
  width:100%;
}